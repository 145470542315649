import React from 'react'
import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { Link } from 'gatsby'
import { breakpoints } from 'src/utils/styles/global-styles'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const CookieConsentBanner = () => {
  const { t } = useTranslation('common')

  const location = useLocation()
  return (
    <CookieSection>
      <CookieConsent
        containerClasses="cookieContainer"
        contentClasses="contentContainer"
        location="bottom"
        buttonText={t('Accept')}
        declineButtonText={t('Decline')}
        buttonId="btnAccept"
        declineButtonId="btnDecline"
        buttonWrapperClasses="btnWrapper"
        disableButtonStyles
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => {
          Cookies.set('gatsby-gdpr-google-analytics', true)
          Cookies.set('gatsby-gdpr-google-tagmanager', true)
          initializeAndTrack(location)
        }}
        enableDeclineButton
        onDecline={() => {
          Cookies.set('gatsby-gdpr-google-analytics', false)
          Cookies.set('gatsby-gdpr-google-tagmanager', false)
        }}
      >
        <Container>
          <CookieText>
            <Trans>This website uses cookies to ensure you have the best experience.</Trans>
            <LearnLink to="/privacy-policy/">
              <Trans>Learn More.</Trans>
            </LearnLink>
          </CookieText>
        </Container>
      </CookieConsent>
    </CookieSection>
  )
}

export default CookieConsentBanner

const CookieText = styled.p`
  font-family: 'filsonProBook';
  color: ${(props) => props.theme.blueDark};
  line-height: 26px;
  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: 5px;
  }
  @media (max-width: 340px) {
    font-size: 15px;
  }
`
const LearnLink = styled(Link)`
  margin-left: 7px;
`

export const ButtonStylesCss = css`
  font-family: 'filsonProBook';
  border-radius: 25px;
  margin: 10px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.theme.blueDark)};
    color: ${(props) => props.theme.cream};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.blueDark)}`};
  }
`

const CookieSection = styled.div`
  .cookieContainer {
    background-color: ${(props) => props.theme.blueLight} !important;
    align-items: center;
    padding: 10px 5%;
    border-top: 1px solid ${(props) => props.theme.blueDark};
  }
  .contentContainer {
    margin: 0px !important;
    flex: auto !important;
  }
  .btnWrapper {
    @media (max-width: ${breakpoints.s}px) {
      margin: auto;
    }
  }
  #btnAccept {
    ${ButtonStylesCss}
    background-color: ${(props) => props.theme.blueDark};
    color: ${(props) => props.theme.cream};
    padding: 10px 30px;
    border-style: none;
    @media (max-width: 375px) {
      padding: 10px 22px;
    }
  }
  #btnDecline {
    ${ButtonStylesCss}
    background-color:${(props) => props.theme.blueLight};
    color: ${(props) => props.theme.blueDark};
    padding: 8px 28px;
    border: 2px solid ${(props) => props.theme.blueDark};
    @media (max-width: 375px) {
      padding: 8px 22px;
    }
  }
`
const Container = styled.div``

import { executeShopifyMutation } from '../ShopifyApiRequest'
import { CART_FRAGMENT } from '../ShopifyApiRequest/CartFragment.js'
import { fetchSellingPlansByVariant } from '../ShopifyQueries/FetchSellingPlanId'

const ADD_CART_LINES_MUTATION = `
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ${CART_FRAGMENT}
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const addCartLines = async (cartId, lines) => {
  const linesWithSellingPlan = await Promise.all(
    lines.map(async (line) => {
      try {
        const sellingPlanId = await fetchSellingPlansByVariant(line.merchandiseId)

        if (sellingPlanId) {
          return {
            ...line,
            sellingPlanId,
          }
        }
        return line
      } catch (error) {
        console.error(
          `Error fetching selling plan for merchandise ID: ${line.merchandiseId}`,
          error.message || error
        )
        return line
      }
    })
  )

  return executeShopifyMutation(
    ADD_CART_LINES_MUTATION,
    { cartId, lines: linesWithSellingPlan },
    'cartLinesAdd'
  )
}

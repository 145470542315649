const theme = {
  textRegular: '#444444',
  textDarker: '#444444',
  backgroundLight: '#f8f6f5',
  backgroundDark: '#2c211b',
  red: '#f55',
  coral: '#f66951',
  redBright: '#FF5651',
  redLight: '#F76950',
  blueLightest: '#FAFFFF',
  blue: '#52C7EB',
  blueLight: '#dcf1f3',
  blueDark: '#2178af',
  blueMedium: '#a8d4d3',
  green: '#1D9182',
  greenForest: '#37632F',
  teal: '#87D4B6',
  forest: '#52B389',
  bluish: '#4AAFB3',
  yellow: '#ffc439',
  yellowLight: '#FEFBE8',
  cream: '#FFFEF9',
  gold: '#F3CB4E',
  golden: '#ce812d',
  gray: '#b6aca6',
  grayDark: '#444444',
  grayDarker: '#505050',
  grayLight: '#9099A0',
  whiteLight: '#F8F9F9',
  brown: '#6D481C',
  orange: '#fdc36d',
  pastelBlue: '#edf9ff',
  lilac: '#f1f0fb',
  violet: '#80A2F8',
  holidayRed: '#CC3B48',
  pink: '#F1CFCE',
  mediumPink: '##ED9D9C',
  darkPink: '#954341',
  lavender: '#D6D5E7',
  emerald: '#043A3C',
  lime: '#B2E3AA',
  glow: '#25C587',
  sage: '#B7CBBF',
  space: '#2B2B2B',
  cloud: '#FAF7F3',
  white: '#FFFFFF',
  primeBlue: '#0578FF',
}

export default theme

import { shopifyApiRequest } from '../ShopifyApiRequest'

export const fetchSellingPlansByVariant = async (variantId) => {
  const FETCH_SELLING_PLANS_QUERY = `
    query fetchSellingPlans($variantId: ID!) {
      node(id: $variantId) {
        ... on ProductVariant {
          id
          sellingPlanAllocations(first: 10) {
            edges {
              node {
                sellingPlan {
                  id
                }
              }
            }
          }
        }
      }
    }
  `

  try {
    const response = await shopifyApiRequest(FETCH_SELLING_PLANS_QUERY, { variantId })
    const sellingPlanId = response.node?.sellingPlanAllocations?.edges[0]?.node?.sellingPlan?.id

    if (sellingPlanId) {
      return sellingPlanId
    }
    return null
  } catch (error) {
    console.error('Failed to fetch selling plans:', error.message || error)
    throw error
  }
}

const SHOPIFY_API_URL = `https://${process.env.GATSBY_SHOPIFY_STORE_NAME}/api/2023-10/graphql.json`

export const shopifyApiRequest = async (query, variables = {}) => {
  try {
    const response = await fetch(SHOPIFY_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': `${process.env.GATSBY_SHOPIFY_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    const data = await response.json()

    if (data.errors) {
      console.error('API errors:', data.errors)
      throw new Error(`API error: ${JSON.stringify(data.errors)}`)
    }

    return data.data
  } catch (error) {
    console.error('Error in shopifyApiRequest:', error)
    throw error
  }
}

export const executeShopifyMutation = async (mutation, variables, responseKey) => {
  try {
    const data = await shopifyApiRequest(mutation, variables)

    if (data?.[responseKey]?.cart) {
      return data[responseKey].cart
    }

    console.error(`Failed to execute mutation (${responseKey}):`, data.userErrors || data.errors)
    return null
  } catch (error) {
    console.error(`Error executing mutation (${responseKey}):`, error)
    throw error
  }
}

import { executeShopifyMutation } from '../ShopifyApiRequest'
import { CART_FRAGMENT } from '../ShopifyApiRequest/CartFragment'
import { generateCustomAttrs } from '../CustomAttributes'

const CREATE_CART_MUTATION = `
  mutation {
    cartCreate {
      cart {
        ${CART_FRAGMENT}
      }
    }
  }
`

export const createNewCart = async () => {
  const newCart = await executeShopifyMutation(CREATE_CART_MUTATION, {}, 'cartCreate')

  if (newCart && newCart.id) {
    generateCustomAttrs(async (customAttributes) => {
      try {
        const updatedCart = await executeShopifyMutation(
          `
          mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
            cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
              cart {
                ${CART_FRAGMENT}
              }
            }
          }
        `,
          {
            cartId: newCart.id,
            attributes: customAttributes,
          },
          'cartAttributesUpdate'
        )
      } catch (error) {
        console.error('Error updating cart attributes:', error)
      }
    })
  }

  return newCart
}

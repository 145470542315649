const QueryParams = {
  from: (window) => {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    const params = {}
    for (let i = 0; i < vars.length; i++) {
      const [k, v] = vars[i].split('=')
      if (k.length > 0) {
        params[decodeURIComponent(k)] = decodeURIComponent(v)
      }
    }
    return params
  },
}

export default QueryParams

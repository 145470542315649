import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import {
  NavigationBar,
  Container,
  LogoContainer,
  SocialContainer,
  NavLeft,
  NavRight,
  NavLink,
} from './styles'

const BlogNavigation = () => {
  const { origamiLogo, facebook, instagram, twitter } = useStaticQuery(graphql`
    query BlogNavigationQuery {
      origamiLogo: file(relativePath: { eq: "icons/social/origami.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      facebook: file(relativePath: { eq: "icons/social/facebook.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      instagram: file(relativePath: { eq: "icons/social/instagram.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      twitter: file(relativePath: { eq: "icons/social/twitter.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const socialMedia = [
    {
      image: twitter,
      link: 'https://twitter.com/skylightframe',
      alt: 'Twitter Logo',
    },
    {
      image: facebook,
      link: 'https://www.facebook.com/ourskylight/',
      alt: 'Facebook Logo',
    },
    {
      image: instagram,
      link: 'https://www.instagram.com/skylightframe/',
      alt: 'Instagram Logo',
    },
  ]

  return (
    <NavigationBar>
      <Container>
        <NavLeft>
          <div>
            <NavLink to="/pages/about-us/">
              <Trans>About</Trans>
            </NavLink>
            <NavLink to="/blog/">Blog</NavLink>
          </div>
          <div>
            <LogoContainer>
              <GatsbyImage image={origamiLogo.childImageSharp.gatsbyImageData} alt="Origami Logo" />
            </LogoContainer>
          </div>
        </NavLeft>
        <NavRight>
          {socialMedia.map((social) => (
            <a href={social.link} target="_blank" rel="noopener noreferrer">
              <SocialContainer>
                <GatsbyImage
                  image={social.image.childImageSharp.gatsbyImageData}
                  alt={social.alt}
                />
              </SocialContainer>
            </a>
          ))}
        </NavRight>
      </Container>
    </NavigationBar>
  )
}

export default BlogNavigation

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basic-single-column-blog-post-index-js": () => import("./../../../src/pages/basicSingleColumnBlogPost/index.js" /* webpackChunkName: "component---src-pages-basic-single-column-blog-post-index-js" */),
  "component---src-pages-blogpost-index-js": () => import("./../../../src/pages/blogpost/index.js" /* webpackChunkName: "component---src-pages-blogpost-index-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-calendar-max-js": () => import("./../../../src/pages/calendar-max.js" /* webpackChunkName: "component---src-pages-calendar-max-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-frame-2-js": () => import("./../../../src/pages/frame-2.js" /* webpackChunkName: "component---src-pages-frame-2-js" */),
  "component---src-pages-frame-amazon-js": () => import("./../../../src/pages/frame-amazon.js" /* webpackChunkName: "component---src-pages-frame-amazon-js" */),
  "component---src-pages-frame-greeting-card-js": () => import("./../../../src/pages/frame-greeting-card.js" /* webpackChunkName: "component---src-pages-frame-greeting-card-js" */),
  "component---src-pages-frame-js": () => import("./../../../src/pages/frame.js" /* webpackChunkName: "component---src-pages-frame-js" */),
  "component---src-pages-gift-guide-js": () => import("./../../../src/pages/gift-guide.js" /* webpackChunkName: "component---src-pages-gift-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-index-js": () => import("./../../../src/pages/landing/index.js" /* webpackChunkName: "component---src-pages-landing-index-js" */),
  "component---src-pages-mental-load-report-js": () => import("./../../../src/pages/mental-load-report.js" /* webpackChunkName: "component---src-pages-mental-load-report-js" */),
  "component---src-pages-pages-about-us-js": () => import("./../../../src/pages/pages/about-us.js" /* webpackChunkName: "component---src-pages-pages-about-us-js" */),
  "component---src-pages-pages-how-to-use-skylight-plus-js": () => import("./../../../src/pages/pages/how-to-use-skylight-plus.js" /* webpackChunkName: "component---src-pages-pages-how-to-use-skylight-plus-js" */),
  "component---src-pages-pages-loading-js": () => import("./../../../src/pages/pages/loading.js" /* webpackChunkName: "component---src-pages-pages-loading-js" */),
  "component---src-pages-pages-loading-plus-js": () => import("./../../../src/pages/pages/loading-plus.js" /* webpackChunkName: "component---src-pages-pages-loading-plus-js" */),
  "component---src-pages-pages-one-click-purchase-js": () => import("./../../../src/pages/pages/one-click-purchase.js" /* webpackChunkName: "component---src-pages-pages-one-click-purchase-js" */),
  "component---src-pages-pages-refer-a-friend-js": () => import("./../../../src/pages/pages/refer-a-friend.js" /* webpackChunkName: "component---src-pages-pages-refer-a-friend-js" */),
  "component---src-pages-pages-reset-test-group-js": () => import("./../../../src/pages/pages/reset-test-group.js" /* webpackChunkName: "component---src-pages-pages-reset-test-group-js" */),
  "component---src-pages-pages-retailers-js": () => import("./../../../src/pages/pages/retailers.js" /* webpackChunkName: "component---src-pages-pages-retailers-js" */),
  "component---src-pages-pages-support-js": () => import("./../../../src/pages/pages/support.js" /* webpackChunkName: "component---src-pages-pages-support-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-prior-tos-js": () => import("./../../../src/pages/prior-tos.js" /* webpackChunkName: "component---src-pages-prior-tos-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-psti-compliance-js": () => import("./../../../src/pages/psti-compliance.js" /* webpackChunkName: "component---src-pages-psti-compliance-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-single-column-blog-post-index-js": () => import("./../../../src/pages/singleColumnBlogPost/index.js" /* webpackChunkName: "component---src-pages-single-column-blog-post-index-js" */),
  "component---src-pages-snapframesetup-js": () => import("./../../../src/pages/snapframesetup.js" /* webpackChunkName: "component---src-pages-snapframesetup-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-templates-blog-home-index-js": () => import("./../../../src/templates/BlogHome/index.js" /* webpackChunkName: "component---src-templates-blog-home-index-js" */),
  "component---src-templates-calendar-product-page-index-js": () => import("./../../../src/templates/CalendarProductPage/index.js" /* webpackChunkName: "component---src-templates-calendar-product-page-index-js" */),
  "component---src-templates-contentful-landing-page-index-js": () => import("./../../../src/templates/ContentfulLandingPage/index.js" /* webpackChunkName: "component---src-templates-contentful-landing-page-index-js" */),
  "component---src-templates-frame-product-page-index-js": () => import("./../../../src/templates/FrameProductPage/index.js" /* webpackChunkName: "component---src-templates-frame-product-page-index-js" */),
  "component---src-templates-home-page-with-coupon-index-js": () => import("./../../../src/templates/HomePageWithCoupon/index.js" /* webpackChunkName: "component---src-templates-home-page-with-coupon-index-js" */),
  "component---src-templates-plus-product-page-index-js": () => import("./../../../src/templates/PlusProductPage/index.js" /* webpackChunkName: "component---src-templates-plus-product-page-index-js" */),
  "component---src-templates-shopify-page-index-js": () => import("./../../../src/templates/ShopifyPage/index.js" /* webpackChunkName: "component---src-templates-shopify-page-index-js" */),
  "component---src-templates-snap-frame-page-index-js": () => import("./../../../src/templates/SnapFramePage/index.js" /* webpackChunkName: "component---src-templates-snap-frame-page-index-js" */),
  "component---src-templates-upsell-product-page-index-js": () => import("./../../../src/templates/UpsellProductPage/index.js" /* webpackChunkName: "component---src-templates-upsell-product-page-index-js" */)
}


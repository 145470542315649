import { useCallback } from 'react'

export const useBannerManagement = (updateStore) => {
  const updateBanner = useCallback(
    (promoCode, channel, amount) => {
      localStorage.setItem('banner', JSON.stringify({ promoCode, channel, amount }))
      updateStore((prevState) => ({
        ...prevState,
        banner: { promoCode, channel, amount },
      }))
    },
    [updateStore]
  )

  const checkAndApplyExistingBanner = useCallback(() => {
    const existingBanner = JSON.parse(localStorage.getItem('banner'))
    if (existingBanner) {
      const { promoCode, channel, amount } = existingBanner
      updateBanner(promoCode, channel, amount)
    }
  }, [updateBanner])

  return { updateBanner, checkAndApplyExistingBanner }
}

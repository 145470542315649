export const CART_FRAGMENT = `
  id
  checkoutUrl
  cost {
    subtotalAmount {
      amount
    }
  }
  discountCodes {
    code
  }
  discountAllocations {
    discountedAmount {
      amount
    }
  }
  totalQuantity
  lines(first: 10) {
    edges {
      node {
        id
        quantity
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
       sellingPlanAllocation {
         sellingPlan {
           id
         }
       }
        merchandise {
          ... on ProductVariant {
            id
            title
            image {
              url
            }
            priceV2 {
              amount
              currencyCode
            }
            product {
              title
              handle
            }
          }
        }
      }
    }
  }
`

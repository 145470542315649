import { executeShopifyMutation } from '../ShopifyApiRequest'
import { CART_FRAGMENT } from '../ShopifyApiRequest/CartFragment.js'

const REMOVE_DISCOUNT_MUTATION = `
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ${CART_FRAGMENT}
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const removeDiscountFromCart = async (cartId) =>
  executeShopifyMutation(
    REMOVE_DISCOUNT_MUTATION,
    {
      cartId,
      discountCodes: [], // Passing an empty array removes all discounts
    },
    'cartDiscountCodesUpdate'
  )

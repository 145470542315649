import { executeShopifyMutation } from '../ShopifyApiRequest'
import { CART_FRAGMENT } from '../ShopifyApiRequest/CartFragment'

const REMOVE_CART_LINES_MUTATION = `
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ${CART_FRAGMENT}
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const removeCartLineItems = async (cartId, lineIds) =>
  executeShopifyMutation(REMOVE_CART_LINES_MUTATION, { cartId, lineIds }, 'cartLinesRemove')

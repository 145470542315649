import React, { useContext, useEffect, useState } from 'react'

import theme from 'src/utils/styles/theme'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import StoreContext from '../../context/StoreContext'
import { Container, Text, BannerContent, HolidayImage } from './styles'
import { IS_FOREIGN_COUNTRY, LOCALIZATION, IN_AU, IN_CA } from '../../utils/constants'
import { bannerHTMLFormatter } from './utils'
import { RichText } from '../Contentful/atoms/RichText'

function getUtmParameter(paramName) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(paramName)
}

const Banner = ({ page }) => {
  const { holidayBannerMobile, bannerV2 } = useStaticQuery(graphql`
    query BannerV2Query {
      holidayBannerMobile: file(relativePath: { eq: "giftGuide/holiday-banner-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      bannerV2: allContentfulBannerV2 {
        edges {
          node {
            color
            textColor
            influencerColor
            influencerTextColor
            es {
              raw
            }
            fr {
              raw
            }
            it {
              raw
            }
            ie {
              raw
            }
            nl {
              raw
            }
            de {
              raw
            }
            en {
              raw
            }
            en_au: enAu {
              raw
            }
            en_ca: enCa {
              raw
            }
            en_calendar: enCalendar {
              raw
            }
            en_eu: enEu {
              raw
            }
            en_gb: enGb {
              raw
            }
          }
        }
      }
    }
  `)

  const [showBanner, setShowBanner] = useState(false)
  const [discountApplied, setDiscountApplied] = useState(false)

  const {
    store: {
      cart,
      banner: { promoCode, channel, amount },
    },
    applyDiscount,
    removeDiscount,
    updateBanner,
  } = useContext(StoreContext)

  const { id } = cart
  const discountCode = cart?.discountCodes?.[0]?.code || ''

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBanner(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!id || !promoCode || discountApplied) return

    const applyOrUpdateDiscount = async () => {
      try {
        // if (discountCode && discountCode.toUpperCase() !== promoCode.toUpperCase()) {
        //   await removeDiscount(id)
        // }

        if (discountCode !== promoCode) {
          await applyDiscount(promoCode)
          setDiscountApplied(true) // Mark as applied
        }
      } catch (error) {
        console.error('Error while applying or removing discount:', error)
      }
    }

    applyOrUpdateDiscount()
  }, [id, promoCode, discountCode, applyDiscount, removeDiscount, discountApplied])

  // Additional useEffect to reset discountApplied state when promoCode changes
  useEffect(() => {
    setDiscountApplied(false)
  }, [promoCode])

  useEffect(() => {
    const source = getUtmParameter('utm_source')
    const utm_campaign = getUtmParameter('utm_campaign')
    if (source === 'babylist') {
      updateBanner('BABYLIST', 'email', '25%')
    }
    if (source === 'homeedit') {
      updateBanner('HOMEEDIT', 'email', '20%')
    }
    if (utm_campaign === 'cheer_video' || utm_campaign === 'cheer_banner') {
      updateBanner('CHEER', 'PodcastFrame', '$15')
    }
  }, [])

  const MARKETING_BANNER_OVERRIDES = {
    Toast: `Thanks for listening! Use Code ${promoCode} for ${amount} off!`,
    Listening: `Thanks for listening! Use code ${promoCode} for ${amount} off your Calendar purchase!`,
    Influencer: `We're so glad you're here! Please use ${promoCode} for ${amount} off your 15" Calendar purchase.`,
    InfluencerMax: `We're so glad you're here! Please use ${promoCode} for ${amount} off your 27" Cal Max purchase.`,
    Today: `We're so glad you're here! Please use ${promoCode} for ${amount} off your 10" Frame purchase.`,
    InfluencerFrame: `We're so glad you're here! Please use ${promoCode} for ${amount} off your frame purchase.`,
    PodcastFrame: `Thanks for listening! Use code ${promoCode} for ${amount} off your Frame purchase!`,
    PodcastBoth: `Thanks for listening! Use code ${promoCode} for ${amount} off!`,
    GMA: `We're so glad you're here! Please use GMA for $50 off your 10" or 15" Calendar purchase.`,
    DirectMail: `Use code ${promoCode} for ${amount} off 10" and 15" Calendars!`,
    PR: `We're so glad you're here. Use ${promoCode} for ${amount} off your 10" or 15" Calendar purchase.`,
    TVFrame: `Use code TV20 FOR $20 OFF your Frame at checkout`,
    TVCal: `${
      IS_FOREIGN_COUNTRY
        ? 'Use code TV FOR 20% OFF your Frame at checkout'
        : 'Use code TV15 FOR 15% OFF your Calendar at checkout'
    }`,
  }

  const discountMessages = {
    BABYLIST:
      'Cyber Week Sale ✨ Save $30 off select Skylight Frames. BABYLIST discount code automatically applied at checkout.',
    CHEER: 'Take $15 off your purchase with the code CHEER',
    HOMEEDIT:
      'Enjoy 20% off your Skylight Calendar purchase. Discount automatically applied at checkout.',
    CASEFILE: `Thanks for listening! Use code CASEFILE for ${
      IN_AU ? '20%' : '15%'
    } off your Frame purchase!`,
    MFM: `Thanks for listening! Use code MFM for ${
      IN_AU || IN_CA ? '20%' : '15%'
    } off your Frame purchase!`,
    LOVE: `Thanks for listening! Use code LOVE for ${
      IN_CA ? '20%' : '$20'
    } off your Frame purchase!`,
    FUN: `Thanks for listening! Use code FUN for ${IN_CA ? '20%' : '$20'} off your Frame purchase!`,
  }

  let backgroundColor = theme[bannerV2?.edges?.[0]?.node?.color] || theme.blueDark
  if (bannerV2?.edges?.[0]?.node?.color === 'holiday') {
    backgroundColor = 'holiday'
  }
  const textColor = theme[bannerV2?.edges?.[0]?.node?.color] || 'white'
  let linkColor = theme[bannerV2?.edges?.[0]?.node?.linkColor] || theme.coral
  if (bannerV2?.edges?.[0]?.node?.color === 'holiday') {
    linkColor = '#fff'
  }
  let influencerBackgroundColor =
    theme[bannerV2?.edges?.[0]?.node?.influencerColor] || theme.lavendar
  let influencerTextColor = theme[bannerV2?.edges?.[0]?.node?.influencerTextColor] || theme.emerald
  let influencerLinkColor = theme[bannerV2?.edges?.[0]?.node?.influencerLinkColor] || theme.emerald
  if (bannerV2?.edges?.[0]?.node?.influencerColor === 'holiday') {
    influencerBackgroundColor = 'holiday'
    influencerTextColor = '#fff'
    influencerLinkColor = '#fff'
  }
  const { pathname } = useLocation()

  const [bannerText, setBannerText] = useState(null)

  useEffect(() => {
    const isCalendarPage = pathname.includes('calendar')
    const BANNER_COPY = bannerV2.edges[0].node
    const normalizedLocalization = LOCALIZATION.replace('-', '_')
    if (
      isCalendarPage &&
      !IS_FOREIGN_COUNTRY &&
      JSON.parse(BANNER_COPY.en_calendar.raw).value !== 'NONE'
    ) {
      setBannerText(BANNER_COPY.en_calendar)
    } else if (BANNER_COPY[normalizedLocalization]?.raw) {
      setBannerText(BANNER_COPY[normalizedLocalization])
    } else {
      setBannerText(null)
    }
  }, [pathname])

  if (promoCode) {
    return (
      <Container
        bgColor={
          influencerBackgroundColor === 'holiday' ? 'transparent' : influencerBackgroundColor
        }
        linkColor={influencerLinkColor}
        className="banner"
      >
        {influencerBackgroundColor === 'holiday' && (
          <HolidayImage
            image={holidayBannerMobile.childImageSharp.gatsbyImageData}
            alt="Holiday Themed background"
          />
        )}
        <Text textcolor={influencerTextColor}>
          {showBanner && (
            <>{MARKETING_BANNER_OVERRIDES[channel] || bannerHTMLFormatter(promoCode, amount)}</>
          )}
        </Text>
      </Container>
    )
  }

  if (discountMessages[promoCode]) {
    return (
      <Container bgColor={backgroundColor} className="banner">
        <Text>{showBanner && <>{discountMessages[promoCode]}</>}</Text>
      </Container>
    )
  }
  if (bannerText) {
    return (
      <Container
        bgColor={backgroundColor === 'holiday' ? 'transparent' : backgroundColor}
        className="banner"
      >
        {backgroundColor === 'holiday' && (
          <HolidayImage
            image={holidayBannerMobile.childImageSharp.gatsbyImageData}
            alt="Holiday Themed background"
          />
        )}
        <BannerContent textColor={textColor} linkColor={linkColor}>
          <RichText content={bannerText} />
        </BannerContent>
      </Container>
    )
  }

  return null
}
Banner.propTypes = {
  page: PropTypes.string,
}

export default Banner

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Analytics from 'src/utils/Analytics'
import theme from 'src/utils/styles/theme'

const ConditionalCTA = ({ url, text, anchorComponent, linkComponent, analyticsEvent, page }) => {
  const [redirecting, setRedirecting] = useState(false)
  if (!!analyticsEvent) {
    const Anchor = anchorComponent
    return (
      <Anchor
        href="#"
        onClick={() => {
          setRedirecting(true)
          Analytics.track(analyticsEvent)
          window.location.href = url
        }}
        style={redirecting ? { backgroundColor: theme.coral } : {}}
      >
        {text}
      </Anchor>
    )
  }
  if (url.toLowerCase().startsWith('http')) {
    const Anchor = anchorComponent
    return <Anchor href={url}>{text}</Anchor>
  }
  const Link = linkComponent
  return <Link to={url}>{text}</Link>
}

ConditionalCTA.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  anchorComponent: PropTypes.object.isRequired,
  linkComponent: PropTypes.object.isRequired,
  analyticsEvent: PropTypes.string,
  page: PropTypes.string,
}

export default ConditionalCTA

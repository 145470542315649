import { shopifyApiRequest } from '../ShopifyApiRequest'
import { CART_FRAGMENT } from '../ShopifyApiRequest/CartFragment.js'

const FETCH_CART_QUERY = `
  query fetchCart($id: ID!) {
    cart(id: $id) {
      ${CART_FRAGMENT}
    }
  }
`

export const fetchCart = async (cartId) => {
  try {
    const data = await shopifyApiRequest(FETCH_CART_QUERY, { id: cartId })
    if (data?.cart) {
      return data.cart
    }
    console.error('Failed to fetch cart:', data.errors)
    return null
  } catch (error) {
    console.error('Error fetching cart:', error)
    throw error
  }
}

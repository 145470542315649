// Contentful-specific rich-text rendering
// https://www.gatsbyjs.com/plugins/gatsby-source-contentful/#rendering

import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Bold = styled.span`
  font-family: 'FilsonProBold';
`

const Text = styled.p`
  font-family: 'FilsonPro';
`

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Text>{children}</Text>,
  },
}

export const RichText = ({ content }) => <>{content && renderRichText(content, options)}</>

RichText.propTypes = {
  content: PropTypes.shape({
    raw: PropTypes.string.isRequired,
  }),
}

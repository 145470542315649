import ABTestGroup from 'src/utils/ABTestGroup'
import AmplitudeDeviceID from 'src/utils/AmplitudeDeviceID'
import UTMParams from 'src/utils/UTMParams'

export const generateCustomAttrs = (onSuccess) => {
  const customAttributes = []
  const abTestGroup = ABTestGroup.current()
  if (abTestGroup) {
    customAttributes.push({ key: 'ABTestGroup', value: abTestGroup })
  }

  const utmParams = UTMParams.get()
  Object.keys(utmParams).forEach((key) => {
    customAttributes.push({ key, value: utmParams[key] })
  })

  AmplitudeDeviceID.get((amplitudeDeviceID) => {
    if (amplitudeDeviceID) {
      customAttributes.push({ key: 'AmplitudeDeviceId', value: amplitudeDeviceID })
    }
    console.log('Generated Custom Attributes:', customAttributes)
    onSuccess(customAttributes)
  })
}

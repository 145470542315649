import QueryParams from './QueryParams'

const LOCAL_STORAGE_KEY = 'utm_params'
const UTM_KEYS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

const UTMParams = {
  get: () => {
    const utmParams = localStorage.getItem(LOCAL_STORAGE_KEY)
    if (!utmParams) return {}
    return JSON.parse(utmParams)
  },
  save: (window) => {
    const queryParams = QueryParams.from(window)
    if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
      const utmParams = {}
      UTM_KEYS.forEach((key) => {
        if (queryParams[key]) utmParams[key] = queryParams[key]
      })
      if (Object.keys(utmParams).length > 0)
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(utmParams))
    }
  },
}

export default UTMParams

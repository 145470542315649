import { executeShopifyMutation } from '../ShopifyApiRequest'
import { CART_FRAGMENT } from '../ShopifyApiRequest/CartFragment.js'

const UPDATE_CART_LINES_MUTATION = `
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ${CART_FRAGMENT}
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const updateCartLineItems = async (cartId, lines) =>
  executeShopifyMutation(UPDATE_CART_LINES_MUTATION, { cartId, lines }, 'cartLinesUpdate')

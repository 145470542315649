import { breakpoints } from 'src/utils/styles/global-styles'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

export const NavigationBar = styled.div`
  padding: 20px 0px;
  display: flex;
  align-items: center;
  background-color: #dfe0e1;
  @media (max-width: ${breakpoints.s}px) {
    padding: 15px 0px;
  }
`
export const Container = styled.header`
  width: 90%;
  margin: auto;
  display: flex;
`
export const LogoContainer = styled.div`
  width: 50px;
`
export const SocialContainer = styled.div`
  width: 25px;
  margin: 0px 10px;
  @media (max-width: ${breakpoints.s}px) {
    width: 20px;
    margin: 0px 5px;
  }
`
const LinkStyles = css`
  color: ${(props) => props.theme.grayDarker};
  text-decoration: none;
  cursor: pointer;
  outline-style: none;
  &:hover {
    opacity: 0.75;
    outline: none;
    outline-style: none;
  }
`
export const NavLink = styled(Link)`
  ${LinkStyles}
  font-family: Helvetica;
  font-size: 18px;
  padding-right: 15px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
  }
`
export const NavLeft = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex: 1 0 53%;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
  }
  @media (max-width: ${breakpoints.m}px) {
    flex: 1 0 58%;
  }
`
export const NavRight = styled.div`
  width: 47%;
  flex-grow: 0;
  flex-shrink: 1;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${breakpoints.m}px) {
    width: 42%;
  }
`

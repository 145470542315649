/* eslint-disable no-undef */
const AmplitudeDeviceID = {
  get: (callback) => {
    try {
      amplitude.init(process.env.GATSBY_AMPLITUDE_KEY, null, null, () => {})
      callback(amplitude.getDeviceId())
    } catch (err) {
      Sentry.captureException(err)
      callback(null)
    }
  },
}
/* eslint-enable no-undef */

export default AmplitudeDeviceID

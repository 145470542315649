import { useCallback } from 'react'
import { createNewCart } from '../ShopifyMutations/CreateNewCart'
import { fetchCart } from '../ShopifyQueries/FetchCart'

export const useCartInitialization = (store, updateStore) => {
  const initializeCart = useCallback(async () => {
    const isBrowser = typeof window !== 'undefined'
    let existingCartId = isBrowser ? localStorage.getItem('shopify_cart_id') : null

    if (existingCartId) {
      // Attempt to load the existing cart if a cart ID is found
      try {
        const existingCart = await fetchCart(existingCartId)
        if (existingCart) {
          updateStore((prevState) => ({
            ...prevState,
            cart: existingCart,
            loading: false,
          }))
          return existingCart
        }
        console.error('Cart not found. Removing invalid cart ID.')
        localStorage.removeItem('shopify_cart_id')
        existingCartId = null
      } catch (error) {
        console.error('Error loading existing cart:', error)
        updateStore((prevState) => ({ ...prevState, loading: false }))
        return { error: 'Error loading existing cart.' }
      }
    }

    if (!existingCartId) {
      try {
        const newCart = await createNewCart()

        if (newCart && newCart.id) {
          if (isBrowser) {
            localStorage.setItem('shopify_cart_id', newCart.id)
          }

          updateStore((prevState) => ({
            ...prevState,
            cart: newCart,
            loading: false,
          }))
          return newCart
        }
        console.error('Failed to create a new cart.')
        updateStore((prevState) => ({ ...prevState, loading: false }))
        return { error: 'Failed to create a new cart.' }
      } catch (error) {
        console.error('Error creating new cart:', error)
        updateStore((prevState) => ({ ...prevState, loading: false }))
        return { error: 'Error creating new cart.' }
      }
    }
  }, [updateStore])

  return { initializeCart }
}

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"skylightframe","start_url":"/","background_color":"#52B389","theme_color":"#52B389","display":"minimal-ui","icon":"src/images/skylight-icon.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8a5ec8f867829a0ca94f091b29debc33"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"siteUrl":"https://skylightframe.com","defaultLanguage":"en","fallbackLanguage":"en","trailingSlash":"always","i18nextOptions":{"lng":"en","interpolation":{"escapeValue":false},"defaultNS":"common","keySeparator":false,"nsSeparator":false,"supportedLngs":["en","en-gb","en-ca","en-au","en-eu","ie","fr","it","de","es","nl"],"lowerCaseLng":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:100,300,500,700,900","Bitter:100,300,500,700,900"]},"custom":{"families":["FilsonPro","FilsonProBook","P22MackinacProBook","FilsonProBold","MatterBold","MatterRegular"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-84850562-1","cookieName":"gatsby-gdpr-google-analytics","allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-PLZQCC9","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","defaultDataLayer":{"platform":"gatsby"}},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://0f63d983f95647b99096dd2416ce776d@o392824.ingest.sentry.io/5618773","sampleRate":0.7,"ignoreErrors":["top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http://tt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http://loading.retry.widdit.com/","atomicFindClose","Non-Error promise rejection captured","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage"],"denyUrls":[{},{},{},{},{},{},{},{},{}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
